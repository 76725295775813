/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('feeplan', {
      parent: 'root',
      url: '/feeplan',
      templateUrl: 'apps/feeplan/templates/acp-feeplan.ng.html',
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.feeplan" */ 'apps/feeplan').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('manage.feeplan', {
      parent: 'root',
      url: '/feeplan-biz',
      templateUrl: 'apps/feeplan/templates/acp-feeplan-biz.ng.html',
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.feeplan" */ 'apps/feeplan').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    });
}

export default states;
